/* Form */
input,
textarea {
  border: 0;
  border: 1px solid #dce5f3;
  border-radius: 0.5rem;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  height: 5rem;
  margin-bottom: 2rem;
  outline: 0;
  padding: 0.5rem 1.5rem;
  transition: all 0.3s;

  &:hover {
    border: 1px solid #c8d0e2;
    box-shadow: none;
  }

  &:focus {
    border-color: rgba($main-accent-color, 0.6);
    box-shadow: 0 0 0 1px rgba($main-accent-color, 0.6);
  }
}

textarea {
  margin-bottom: 0;
  max-width: 100%;
  min-height: 10rem;
  min-width: 100%;
  padding: 1.5rem;
}

::placeholder {
  color: #99a3ba;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #99a3ba;
}

::-ms-input-placeholder {
  color: #99a3ba;
}

input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: 1px solid #dce5f3;
  border-radius: 0.2rem;
  height: 2.2rem;
  outline: none;
  transition: all 0.15s;
  width: 2.2rem;

  &:focus {
    border-color: rgba(#b1b9cc, 0.3);
    box-shadow: 0 0 0 1px rgba(#b1b9cc, 0.3);
  }

  &:checked {
    position: relative;
  }

  &:checked:before {
    background-color: #b1b9cc;
    background-image: url("../images/check-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 78% auto;
    border: 1px solid #b1b9cc;
    border-radius: 0.2rem;
    content: "";
    display: block;
    height: 2.2rem;
    left: -1px;
    opacity: 1;
    position: absolute;
    top: -1px;
    width: 2.2rem;
  }
}

.custom-file-upload {
  align-items: center;
  border: 1px solid #dce5f3;
  border-radius: 0.5rem;
  display: flex;
  height: 5rem;
  justify-content: flex-start;
  padding: 1.2rem 1.5rem;
  width: 100%;

  &:hover {
    border: 1px solid #c8d0e2;
    cursor: pointer;
  }

  input[type="file"] {
    display: none;
  }
}

.clear-btn {
  text-indent: -10000000000rem;
  display: block;
  position: absolute;
  right: 1.8rem;
  bottom: 1.5rem;
  background: transparent;
  border: 0;
  background-image: url("../images/remove-file-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  opacity: 0.3;

  &:hover {
    opacity: 0.4;
  }
}

.input {
  align-items: center;
  border: 1px solid #dce5f3;
  border-radius: 0.5rem;
  display: flex;
  height: 5rem;
  justify-content: flex-start;
  padding: 1.2rem 1rem 1.2rem 5rem;
  width: 100%;
  position: relative;

  &:hover {
    border: 1px solid #c8d0e2;
    cursor: pointer;
  }

  .text-btn:hover {
    cursor: pointer;
  }

  &:before {
    border: 1px dashed #dce5f3;
    border-radius: 0.5rem;
    content: "";
    height: 3rem;
    margin-right: 1rem;
    position: absolute;
    width: 3rem;
    left: 1rem;
    z-index: 9;
  }

  .text-btn {
    margin-left: 0;
  }

  .thumb {
    align-items: center;
    display: flex;
    height: 4rem;
    max-width: 11rem;
    background: #ffffff;
    position: relative;
    margin-left: -4rem;
    z-index: 10;

    img {
      display: inline-block;
      max-height: 3.6rem;
      max-width: calc(100% - 1rem);
    }
  }

  button {
    margin-left: 0.5rem;
  }

  .text-btn {
    color: #b3bbcc;
    font-weight: 500;
    border: 0;
    background: transparent;
    padding: 0;
  }
}

.drag-drop {
  border-radius: 0.5rem;
  height: 5rem;
  width: 100%;

  .uploaded {
    align-items: center;
    border: 1px solid #dce5f3;
    border-radius: 0.5rem;
    display: flex;
    font-size: 1.5rem;
    height: 5rem;
    justify-content: space-between;
    padding: 1.2rem 1.5rem;
    color: #000000;

    .file-name {
      margin-right: 1rem;
    }

    .cancel-btn {
      background-color: transparent;
      border: 0;
      color: hsl(0, 0%, 80%);
      padding: 0;

      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }
  }
}

.color-picker {
  align-items: center;
  border: 1px solid #dce5f3;
  border-radius: 0.5rem;
  display: flex;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  height: 5rem;
  justify-content: flex-start;
  outline: 0;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
  transition: all 0.3s;
  width: 100%;
  color: #000000;

  &:hover {
    border: 1px solid #c8d0e2;
    cursor: pointer;
  }

  &:before {
    border: 1px dashed #dce5f3;
    border-radius: 0.5rem;
    content: "";
    height: 3rem;
    margin-right: 1rem;
    position: absolute;
    width: 3rem;
    z-index: 9;
  }

  .placeholder {
    color: #b3bbcc;
  }

  .color-wrapper {
    border-radius: 0.5rem;
    height: 3rem;
    margin-right: 1rem;
    position: relative;
    width: 3rem;
    min-width: 3rem;
    z-index: 10;
    border: 1px solid #e8ecf3;
  }
}

.rcp {
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
  max-width: 100%;
  min-width: 20rem;
  position: absolute;
  top: 8.2rem;
  z-index: 501;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $main-accent-color;
  color: $secondary-accent-color;
  text-shadow: none !important;
}

.rdtPicker td.rdtToday:before {
  border-bottom-color: $main-accent-color;
}
