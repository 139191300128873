/* Editor controls */

.modal-footer {
  .btn-wrapper {
    flex-grow: 1;
    width: 50%;

    a {
      text-decoration: none;
    }

    .btn-primary {
      background-color: #dce5f3;
      color: #99a3ba;
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.active {
      .btn-primary {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .modal-btn {
    height: 6rem;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    width: 100%;

    &.btn-primary {
      height: calc(6rem + 2px);
      margin-top: -1px;
      position: relative;
    }
  }
}
