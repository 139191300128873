/* Editor form */

@import "../../assets/styles/variables.scss";

.modal-form {
  height: calc(100% - 7.6rem);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1px;
  position: relative;

  &:after {
    content: "";
    display: block;
    height: 13rem;
    width: 100%;
  }

  .active-switcher {
    position: fixed;
    right: 8rem;
    top: 2rem;
    z-index: 510;
    @media (max-width: 1400px) {
      top: 1.9rem;
    }
    @media (max-width: 480px) {
      top: 0.9rem;
    }
    @media (max-width: 360px) {
      top: 0;
    }
  }

  .switcher-field {
    position: relative;
    top: -1.5rem;
  }
}

.form-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  &.two-one {
    .form-group:first-child {
      margin-right: 1rem;
      width: calc(66% - 1rem);
    }

    .form-group:last-child {
      margin-left: 1rem;
      width: calc(33% - 1rem);
    }

    input {
      width: 100%;
    }
  }

  &.half {
    .form-group:first-child {
      margin-right: 1rem;
      width: calc(50% - 1rem);
    }

    .form-group:last-child {
      margin-left: 1rem;
      width: calc(50% - 1rem);
    }
  }
}

.add-btn {
  background-color: transparent;
  border: none;
  color: #6f7d98;
  cursor: pointer;
  display: block;
  font-weight: 600;
  margin: 1.65rem 0;
  padding: 0 0 0 3rem;
  position: relative;
  text-decoration: none;

  &:after,
  &:before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 2rem;
    left: 0;
    margin-right: 1rem;
    position: absolute;
    top: -0.2rem;
    transition: opacity 0.3s;
    width: 2rem;
  }

  &:before {
    background-image: url("../../assets/images/add-icon.svg");
    opacity: 1;
  }

  &:after {
    background-image: url("../../assets/images/add-icon-active.svg");
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.remove-btn {
  background-color: transparent;
  border: none;
  color: #6f7d98;
  cursor: pointer;
  display: block;
  font-weight: 600;
  margin: 1.55rem 0;
  padding: 0 0 0 3rem;
  position: relative;

  &:after,
  &:before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 2rem;
    left: 0;
    margin-right: 1rem;
    position: absolute;
    top: -0.2rem;
    transition: opacity 0.3s;
    width: 2rem;
  }

  &:before {
    background-image: url("../../assets/images/remove-icon.svg");
    opacity: 1;
  }

  &:after {
    background-image: url("../../assets/images/remove-icon-active.svg");
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

.remove-block {
  justify-content: flex-start;

  .form-col:first-child {
    margin-right: 1rem;
    width: calc(100% - 12rem);
  }

  .form-col:last-child {
    margin-left: 1rem;
    width: 9rem;
  }
}

.assets-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  max-height: 46vh;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.gallery {
    max-height: calc(100% - 18rem);
    justify-content: flex-start;
    max-width: 66rem;
    margin: 0 auto;

    li {
      max-width: 18rem;

      @media (min-width: 1260px) {
        margin-right: calc((100% - 3 * 18rem) / 2);
      }

      @media (min-width: 500px) and (max-width: 600px) {
        margin-right: calc((100% - 3 * 18rem) / 2);
      }

      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &.disabled {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.7;
          background: #f9fbff;
        }
      }

      &.load-more-item {
        padding: 0;
        flex-basis: 100%;
        margin: 2rem 0 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        cursor: auto;

        .load-btn {
          margin: 0 auto;
          display: block;
          max-width: 100%;
          width: 16rem;
          background: #f0f3f9;
          padding: 1rem;
          border-radius: 0.6rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5rem;
          color: #6f7d98;
          font-weight: 600;

          @media (max-width: 1400px) {
            height: 6rem;
            font-size: 1.6rem;
            width: 18rem;
          }

          &:hover {
            cursor: pointer;
            background: #f4f7fb;
          }
        }
      }
    }

    .asset-name {
      margin: 0;
    }

    @media (max-width: 480px) {
      max-height: calc(100% - 21rem);
    }
  }

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 33%;
    order: 2;
    padding: 0 1.5rem;
    position: relative;
    width: 33%;

    @media (max-width: 360px) {
      max-width: 50%;
      width: 50%;
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      right: 2.5rem;
      top: 1rem;
      transition: all 0.3s;
    }

    .img-block {
      align-items: center;
      border-radius: 0.5rem;
      display: flex;
      height: 10rem;
      justify-content: center;
      margin: 0 auto;
      padding: 2rem 1rem;
      width: 100%;
      border: 2px solid transparent;
    }

    &:not(.disabled).selected,
    &:not(.disabled):hover {
      input[type="checkbox"] {
        opacity: 1;
        transition: all 0.3s;
      }

      .asset-block {
        .img-block {
          border: 2px solid rgba($main-accent-color, 0.6);
          background: #ffffff;
          transition: all 0.3s;

          img {
            max-height: 6.5rem;
            transition: all 0.3s;
          }
        }

        .asset-name {
          color: #252c37;
        }
      }
    }

    &:not(.disabled):hover .asset-block {
      .img-block {
        border: 2px solid #e2e9f6;
      }
    }
  }

  .asset-block {
    border-radius: 3px;
    display: inline-block;
    padding-bottom: 1rem;
    width: 100%;

    .img-thumb {
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 15rem;
      max-width: 100%;
      transition: all 0.3s;
    }

    img,
    video {
      border-radius: 3px;
      display: block;
      margin: 0 auto;
      max-height: 15rem;
      max-width: 100%;
      transition: all 0.3s;
    }
  }

  .asset-btn,
  .asset-name {
    color: #6f7d98;
    display: inline-block;
    font-weight: 500;
    overflow: hidden;
    padding: 1rem 1rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .asset-btn {
    cursor: pointer;
  }
}

.preview-modal {
  position: fixed;
  z-index: 600;

  .preview-wrapper {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;

    .close-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: block;
      height: 2rem;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 2.5rem;
      text-indent: -9999rem;
      top: 3.8rem;
      width: 2rem;
      z-index: 610;
      @media (max-width: 480px) {
        top: 2.8rem;
      }

      &:after,
      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        width: 2rem;
      }

      &:before {
        background-image: url("../../assets/images/close-icon.svg");
      }

      &:after {
        background-image: url("../../assets/images/close-icon-active.svg");
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    &:before {
      background-color: #d3ddeb;
      content: "";
      height: 100vh;
      left: 0;
      opacity: 0.8;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: 500;
    }

    .preview-block {
      max-width: 90%;
      position: relative;
      width: 80rem;
      z-index: 600;

      img,
      video {
        height: 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

.search-bar {
  height: 5rem;
  margin-bottom: 3rem;
  position: relative;
  width: 100%;

  .creation-link {
    background: #b4bccb;
    padding-left: 3.5rem;
    padding-right: 6rem;

    &:hover {
      background: #657592;
    }

    .btn-icon {
      right: 2.5rem;
      top: 1.5rem;
    }
  }

  .search-wrapper {
    border: 1px solid #dce5f3;
    border-radius: 0.5rem;
    height: 5rem;
    padding: 0 1.5rem;
    background: #ffffff;

    &:focus-within {
      border: 1px solid #c8d0e2;
      outline: 1px solid #c8d0e2;
    }

    &:hover {
      border: 1px solid #c8d0e2;
    }

    &:before {
      margin: 0 1.5rem 0 0;
    }

    .search {
      display: block;
      width: calc(100% - 4rem);
    }

    @media (max-width: 480px) {
      padding-left: 3rem;
    }

    @media (max-width: 360px) {
      padding-left: 2rem;
    }
  }

  @media (max-width: 800px) {
    .creation-link {
      padding: 2rem 2.5rem;

      .btn-icon {
        right: auto;
        top: auto;
      }
    }

    .search-wrapper,
    .search-wrapper:hover {
      width: 100%;
      right: auto;
      border: 1px solid #dce5f3;
    }
  }
}
