/* Modal wrapper */

@import "../../assets/styles/variables.scss";

.modal-wrapper {
  background: rgba(0, 0, 0, 0.6);
  position: relative;

  .modal {
    background: #f9fbff;
    box-shadow: -0.5rem 0 1rem 1px rgba(darken($main-bg-color, 40%), 0.3);
    height: 100vh;
    max-width: 172rem;
    min-width: 60rem;
    padding: 4rem 4rem 6rem;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 0.5s;
    width: 40vw;
    z-index: 500;

    @media (max-width: 600px) {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
    }

    @media (max-width: 480px) {
      padding: 3rem;
    }

    @media (max-width: 360px) {
      padding: 2rem;
    }

    .close-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: block;
      height: 2rem;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 2.5rem;
      text-indent: -9999rem;
      top: 3.8rem;
      width: 2rem;

      @media (max-width: 480px) {
        top: 2.8rem;
      }

      @media (max-width: 360px) {
        top: 1.8rem;
      }

      &:after,
      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        width: 2rem;
      }

      &:before {
        background-image: url("../../assets/images/close-icon.svg");
      }

      &:after {
        background-image: url("../../assets/images/close-icon-active.svg");
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &.visible:before {
    background-color: darken($main-bg-color, 10%);
    content: "";
    height: 100vh;
    left: 0;
    opacity: 0.5;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 400;
  }

  &.visible .modal {
    transform: translateX(0);
  }

  .loader {
    display: block;
    margin: 4rem auto !important;
  }
}
