/* Web Client Page - Carousel */

.client-page {
  .react-multi-carousel-list {
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 6vw;
      height: 100%;
      right: 0;
      position: absolute;
      top: 0;
      opacity: 0.8;
    }
  }

  .react-multi-carousel-track {
    margin-left: -1rem !important;

    @media (max-width: 900px) {
      margin-left: -1rem !important;
    }
  }

  .react-multiple-carousel__arrow {
    top: calc(9.6vw / 2 - 20px);

    @media (max-width: 1024px) {
      top: calc(12vw / 2 - 20px);
    }

    @media (max-width: 700px) {
      top: calc(16vw / 2 - 20px);
    }

    @media (max-width: 480px) {
      top: calc(24vw / 2 - 20px);
    }
  }

  .react-multiple-carousel__arrow--left {
    left: 1rem;

    &:before {
      left: -0.2rem;
    }
  }

  .react-multiple-carousel__arrow--right {
    right: calc(6% + 0.5rem);

    &:before {
      right: -0.2rem;
    }
  }
}
