/* Editor page */

.editor-page {
  height: 100%;
  color: #252c37;

  p {
    color: #252c37;
  }
}
