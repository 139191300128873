/* Web Client Page - Video */

.client-page {
  .video-wrapper {
    padding: 2rem 5% 4rem;
    position: fixed;
    top: 7rem;
    overflow: hidden;
    z-index: 200;
    width: 100%;
    transition: all 0.3s;

    &:before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: top center;
      transition: all 0.3s;
      top: -8rem;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 100;
    }

    .video-block {
      display: flex;
      border-radius: 0.6rem;
      height: 19.6875vw;
      transition: all 0.3s;

      &.hasDescription {
        box-shadow: 0 0 2rem rgba(0,0,0,0.15);
      }
    }

    .preview-block {
      width: 35vw;
      display: flex;
      justify-content: center;
      overflow: hidden;
      transition: all 0.3s;
      position: relative;
      border-radius: 0.6rem;
      z-index: 110;
      box-shadow: 0 0 2rem rgba(0,0,0,0.15);

      &.hasDescription {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;

        @media (max-width: 900px) {
          border-top-right-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;
        }
      }

      video {
        width: 100%;
        max-height: 100%;
      }

      .ima-ad-container {
        top: 0;
        width: 100%;
        height: 100%;
      }

      &.locked {
        align-items: center;

        img {
          opacity: 0.8;
          width: 100%;
          min-height: 100%;
        }
      }

      .locked-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        color: #eddc87;
        text-align: center;
        padding: 2rem;

        svg {
          position: relative;
          z-index: 99;
          width: 3rem;
          height: 3rem;
          transition: all 0.3s;
        }

        p {
          display: block;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          padding: 1.2rem 0 0;
          font-size: 1.2rem;
        }

        @media (max-width: 400px) {
          p {
            font-size: 1.3rem;
          }
        }
      }
    }

    @media (max-width: 900px) {
      z-index: 100;
    }

    @media (min-width: 901px) and (max-width: 1024px) {
      .video-block {
        height: 23.625vw;
      }

      .preview-block {
        width: 42vw;
      }
    }
  }
}
