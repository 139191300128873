/* Search bar */

.search-wrapper {
  background: transparent;
  border-left: 1px solid #e2e9f6;
  box-shadow: none;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2.5rem;
  position: relative;

  &:before {
    background-image: url("../../assets/images/search-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 2rem;
    margin-right: 1.5rem;
    position: relative;
    transition: all 0.3s;
    width: 2rem;
  }

  .search {
    background: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    margin: 0;
    outline: 0;
    padding: 0.5rem 0;
    position: relative;

    &:focus,
    &:hover {
      border: 0;
      box-shadow: none;
      outline: 0;
    }
  }

  input[type="search"]::-webkit-search-cancel-button {
    opacity: 0.5;
  }

  input[type="search"]::-webkit-search-cancel-button:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  @media (max-width: 800px) {
    align-items: center;
    background: #ffffff;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0 2rem;
    position: absolute;
    right: 7.3rem;
    width: 7.3rem;

    &:before {
      margin: 0 auto;
      top: auto;
    }

    .search {
      display: none;
    }

    &:hover {
      border-left: 0;
      width: calc(100% - 7.3rem);

      &:before {
        margin: 0 1.5rem 0 0;
      }

      .search {
        display: block;
        width: calc(100% - 4rem);
      }
    }
  }
}
