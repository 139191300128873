/* Editor footer */

.modal-footer {
  background: #f9fbff;
  border-top: 1px solid #e2e9f6;
  bottom: 0;
  display: flex;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 500;
}
