/* Variables */

$main-bg-color: #f6f8fb;
$secondary-bg-color: #ecf1fa;
$accent-bg-color: #ffffff;

$main-font-color: #252c37;
$secondary-font-color: #99a3ba;

$main-accent-color: #afcb08;
$secondary-accent-color: #ffffff;

$invalid-primary-color: #f78f7b;
$invalid-secondary-color: #ffdbcc;
