/* Creation link */

.creation-link {
  height: calc(100% + 2px);
  padding-left: 3rem;
  padding-right: 6rem;
  position: relative;

  .btn-icon {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1;
    margin-left: 2rem;
    position: absolute;
    right: 3rem;
    top: 2.2rem;
  }

  @media (max-width: 800px) {
    padding: 2rem 2.7rem;

    .btn-label {
      display: none;
    }

    .btn-icon {
      margin-left: 0;
      position: relative;
      right: auto;
      top: auto;
    }
  }
}
