/* Web Client Page - Category Row */

.client-page {
  .category-wrapper {
    margin-bottom: 5rem;
    position: relative;
    padding-left: 5%;
    width: 100%;
    z-index: 2;

    .wrapper-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 6%;
    }

    .link {
      font-size: 1.6rem;
      font-weight: 900;
      border-radius: 0.3rem;
      min-width: 10rem;
      margin-left: 2rem;

      svg {
        width: 1rem;
        height: 1rem;
        margin-left: 1rem;

        path {
          stroke-width: 1px;
        }
      }
    }
  }
}
