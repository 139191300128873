/* Main header */
@import "../../assets/styles/variables.scss";

.main-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 2.5rem 4rem 0 11rem;
  top: 0;
  width: 100%;
  z-index: 200;
  @media (max-width: 1200px) {
    padding-left: 7rem;
    padding-right: 2rem;
  }
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }

  .account-wrapper {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: 4rem;
    @media (max-width: 600px) {
      order: 2;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    visibility: hidden;
    height: 4.7rem;
    width: 17rem;
    z-index: 200;

    @media (max-width: 1200px) {
      visibility: visible;
    }

    @media (max-width: 600px) {
      order: 1;
    }

    img {
      max-height: 4.7rem;
      max-width: 100%;
    }
  }

  .logout {
    color: #99a3ba;
    cursor: pointer;
    display: block;
    font-weight: 500;
    position: relative;
    transition: all 0.3s;

    &:after,
    &:before {
      background-image: url("../../assets/images/logout-icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      display: inline-block;
      height: 2rem;
      left: -2.7rem;
      position: absolute;
      transition: all 0.3s;
      width: 2rem;
    }

    &:after {
      background-image: url("../../assets/images/logout-icon-active.svg");
      opacity: 0;
    }

    &:hover {
      color: #657592;

      &:before {
        opacity: 0;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .account {
    align-items: center;
    background-color: #b1b9cc;
    border-radius: 5rem;
    color: #ffffff;
    display: flex;
    font-size: 1.8rem;
    font-weight: 500;
    font-weight: 500;
    height: 4.7rem;
    justify-content: center;
    margin-left: 2rem;
    width: 4.7rem;
    @media (max-width: 500px) {
      display: none;
    }
  }
}

.select.top-header-org-unit-selector {
  left: 34rem;
  position: absolute;
  top: 2.5rem;
  width: 23rem;
  @media (max-width: 1200px) {
    left: 28rem;
  }
  @media (max-width: 600px) {
    left: auto;
    position: relative;
    top: auto;
    max-width: 100%;
  }

  &:before {
    background-image: url("../../assets/images/org-icon.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: inline-block;
    height: 2rem;
    left: 1.5rem;
    position: absolute;
    top: 1.5rem;
    transition: all 0.3s;
    width: 2rem;
    z-index: 20;
  }

  .css-1hwfws3 {
    //height: 3rem;
  }

  .custom__control {
    border-color: #ffffff;

    &:hover {
      border-color: #ffffff;
    }
  }

  .custom__indicator-separator {
    display: none;
  }

  .custom__indicator {
    padding: 0.8rem 1.5rem;
  }

  .custom__value-container {
    min-height: 4.7rem;
    padding-left: 4.5rem;
  }

  .custom__single-value {
    color: #99a3ba;
    font-weight: 500;
  }
}
@media (max-width: 600px) {
  .select-wrapper {
    margin-left: -5rem;
    margin-top: 2rem;
    width: calc(100% + 8rem);
    order: 3;
    position: relative;
  }
}
