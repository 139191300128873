/* Web Client Page - Not Found */

.error-wrapper {
  padding: 0 2rem;

  p {
    margin: 2.5rem 0 3rem;
  }

  .btn {
    border-radius: 0.5rem;
    max-width: 20rem;
    width: 100%;
    text-align: center;
    display: inline-block;
    height: 5rem;
    padding: 1.55rem 2rem;
  }
}
