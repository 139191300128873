/* Web Client Page - Search page */

.search-page {
  .center-block {
    margin: 12rem 0;
  }

  .input-wrapper {
    position: fixed;
    top: 8rem;
    z-index: 105;
    width: 100%;
    padding: 4rem 0;
    left: 0;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: top center;
      top: -8rem;
      left: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 105;
    }
  }

  .search-input {
    width: 90%;
    margin-left: 5%;
    padding-left: 5rem;
    position: relative;
    background-image: url("../../../assets/images/search-icon.svg") !important;
    background-position: 1.5rem center;
    background-repeat: no-repeat;
    background-size: auto 2rem;
    height: 5.5rem;
    border-radius: 1rem;
    font-weight: bold;
    z-index: 106;
  }

  .video-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0;
    position: relative;
    top: 15.8rem;

    h4 {
      margin: 2rem auto;
    }
  }

  .slider-item {
    cursor: pointer;
    position: relative;
    margin: 0 1rem 4rem;
    width: calc(100% / 5 - 2rem);

    @media (max-width: 1024px) {
      width: calc(100% / 4 - 2rem);
    }

    @media (max-width: 700px) {
      width: calc(100% / 3 - 2rem);
    }

    @media (max-width: 480px) {
      width: calc(100% / 2 - 2rem);
    }

    &:hover {
      .img-wrapper {
        &:before {
          opacity: 1;

          @media (max-width: 700px) {
            display: none;
          }
        }

        &:after {
          opacity: 1;

          @media (max-width: 700px) {
            display: none;
          }
        }
      }

      .channel-name,
      .program-name {
        opacity: 0.6;
      }
    }

    &.playing-now,
    &.playing-now:hover {
      .img-wrapper {
        &:before {
          opacity: 1;
          display: block;
        }

        &:after {
          content: "Now Playing";
          background-image: none;
          opacity: 1;
          width: 90%;
          color: #ffffff;
          text-align: center;
          height: auto;
          font-size: 1.8rem;
          letter-spacing: 0.1rem;
          font-weight: 600;
          display: block;
          transition: none;
        }
      }

      .channel-name,
      .program-name {
        opacity: 0.6;
      }
    }

    &.locked,
    &.locked:hover {
      .img-wrapper {
        img {
          opacity: 0.8;
        }

        &:before {
          opacity: 1;
          display: block;
        }

        &:after {
          display: none;
        }
      }
    }

    .img-wrapper {
      align-items: center;
      border-radius: 0.6rem;
      display: flex !important;
      height: 9.6vw;
      justify-content: center;
      width: 100%;
      position: relative;
      transition: all 0.3s;
      overflow: hidden;
      margin-bottom: 1rem;

      img {
        &:after {
          content: "";
          display: block;
          position: absolute;
          border-radius: 0.6rem;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      @media (max-width: 1024px) {
        height: 12vw;
      }

      @media (max-width: 700px) {
        height: 16vw;
      }

      @media (max-width: 480px) {
        height: 24vw;
      }

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        opacity: 0;
        transition: all 0.3s;
        z-index: 99;
      }

      &:after {
        content: "";
        position: absolute;
        display: inline-block;
        width: 43px;
        height: 43px;
        background-repeat: no-repeat;
        background-size: 43px;
        background-image: url("../../../assets/images/play-icon.svg");
        background-position: center;
        z-index: 100;
        opacity: 0;
        transition: all 0.3s;
      }

      img {
        border-radius: 0.3rem;
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: 0 !important;
        display: block;
      }

      .live-tag {
        right: 0;
        bottom: 0;
        position: absolute;
        margin: 0;
      }
    }

    .locked-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        position: relative;
        z-index: 99;
        width: 2.5rem;
        height: 2.5rem;
        transition: all 0.3s;
      }
    }

    .channel-name {
      font-size: 1.4rem;
      font-weight: 600;
      display: block;
      margin: 0.5rem 0;
      color: #99a3ba;
      text-transform: uppercase;
      transition: all 0.3s;
    }

    .program-name {
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
      margin: 0.5rem 0 0;
      display: -webkit-box;
      max-width: 100%;
      margin: 0 auto;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 0.3s;

      &.live {
        font-size: 1.8rem;
      }
    }
  }
}
