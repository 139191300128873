/* Web Client Page */

.client-page {
  position: relative;

  &:before {
    content: "";
    position: fixed;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .main {
    min-height: calc(100vh - 10rem);
  }

  .center {
    width: 100%;
    min-height: calc(100vh - 22rem);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  .center-block {
    margin: 10rem 0 4rem;
  }

  p, a, button, div, h3, h2, input {
    letter-spacing: 0.05rem;
  }

  h3 {
    font-size: 2.7rem;
    margin: 2rem 0;
    font-weight: bold;
  }

  .live-tag {
    display: inline-block;
    background: #afcb08;
    padding: 0.55rem 0.5rem 0.45rem 0.7rem;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1rem;
    border-radius: 0.3rem;
    letter-spacing: 0.05rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    letter-spacing: 0.12rem;
    font-weight: 900;
  }

  .tag {
    display: inline-block;
    background: #252c37;
    padding: 0.55rem 0.6rem;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.1rem;
    border-radius: 0.3rem;
    letter-spacing: 0.05rem;
    font-weight: bold;
    letter-spacing: 0.12rem;
    font-weight: 900;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .content {
    transition: all 0.3s;
    margin-bottom: 10rem;

    .video-wrapper {
      border-radius: 0.6rem;

      .description {
        .live-tag {
          padding: 0.75rem 0.6rem 0.7rem 0.85rem;
        }
      }
    }

    &.scroll {
      transition: all 0.3s;

      .video-wrapper {
        padding: 2rem 5% 4rem;

        .video-block {
          height: 16.3125vw;
        }

        .preview-block {
          width: 29vw;
          transition: all 0.3s;
        }

        .description {
          padding: 2rem;
          position: relative;
          transition: all 0.3s;

          .title {
            font-size: 1.4vw;
          }

          .subtitle {
            font-size: 1.6rem;
            margin: 0.5rem 0;
          }

          .live-tag {
            margin-bottom: 0.5rem;
          }

          p {
            font-size: 1.6rem;
            margin-top: 0.5rem;
          }

          .next {
            margin: 2rem 0 0;
            transition: opacity 0.5s ease-out;
            transition-delay: 1s !important;

            @media (max-width: 1024px) {
              opacity: 0;
              transition-duration: 0.1s;
              transition-delay: 0s !important;
            }

            h4 {
              font-size: 1rem;
              margin-bottom: 0.5rem;
            }

            .next-title {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  @media (min-width: 901px) {
    .content {
      margin-top: calc(14.5rem + 19.6875vw) !important;

      &.scroll {
        margin-top: calc(14.5rem + 16.3125vw ) !important;
      }
    }
  }

  @media (min-width: 901px) and (max-width: 1024px) {
    .content {
      margin-top: calc(14.5rem + 23.625vw) !important;

      .description .next .next-title {
        font-size: 1.8rem;
      }
    }
  }

  @media (max-width: 900px) {
    .content,
    .content.scroll {
      .video-wrapper {
        padding: 0;

        .video-block {
          height: auto;
          flex-direction: column;
          padding: 2rem 5% 4rem;
        }

        .preview-block,
        .description {
          width: 100%;
        }

        .preview-block {
          height: 50.625vw;
          width: 90vw;
          max-height: 40vh;
        }

        .description {
          padding: 2.5rem 0;
          background: transparent !important;

          .title {
            font-size: 2.4rem;
            max-width: 100%;
          }

          .subtitle {
            font-size: 1.8rem;
            margin: 0.5rem 0;
          }

          .live-tag {
            margin-bottom: 0.5rem;
            float: right;
          }

          p {
            font-size: 1.8rem;
            max-width: 100%;
          }

          .next {
            margin: 1rem 0 0;
            display: block;

            h4 {
              font-size: 1.6rem;
              text-transform: none;
              float: left;
              margin: 0 1rem 0 0;
            }

            .next-title {
              font-size: 1.6rem;
            }
          }
        }
      }
    }
  }

  .content-wrapper {
    position: relative;
    padding: 8.5rem calc(5% - 1rem);
    z-index: 100;

    .center {
      min-height: calc(100vh - 45rem);
      text-align: center;
    }
  }
}
