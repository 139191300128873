/* Web Client Page - Header */

.client-page {
  .header {
    width: 100%;
    padding: 2rem 5% 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top center;
    min-height: 9rem;
    top: 0;
    z-index: 200;

    .mobile-menu {
      position: fixed;
      top: 0;
      width: 100vw;
      left: 0;
      padding: 2rem 5%;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.3s;
      padding-top: 8rem;
      z-index: 1;

      &.active {
        height: 100vh;
        opacity: 1;
      }

      a {
        text-decoration: none;
        font-size: 1.8rem;
        margin: 4rem 0;
        display: block;
        font-weight: bold;

        &.active {
          font-weight: bold;
        }
      }

      button {
        border: 0;
        background: transparent;
        font-size: 1.8rem;
        margin: 4rem 0;
        padding: 0;
        display: block;
        cursor: pointer;
        font-weight: bold;

        &.active {
          font-weight: bold;
        }
      }
    }

    .main-nav-icon {
      display: none;
      width: 2.6rem;
      height: 2.6rem;
      top: 0.5rem;
      position: relative;
      z-index: 100;
      cursor: pointer;

      &:before,
      &:after,
      div {
        content: "";
        display: block;
        height: 2px;
        border-radius: 0.2rem;
        margin: 3px 0;
        transition: all 0.3s;
        width: 2.6rem;
      }

      @media (max-width: 600px) {
        display: block;
      }

      &.active {
        &:before {
          transform: translateY(0.65rem) rotate(135deg);
        }

        &:after {
          transform: translateY(-0.65rem) rotate(-135deg);
        }

        div {
          transform: scale(0);
        }
      }
    }

    .nav {
      position: relative;
      margin-left: -2rem;
      z-index: 100;

      a {
        text-decoration: none;
        font-size: 1.6rem;
        padding: 0 2rem;
        font-weight: bold;
        transition: all 0.3s;

        &.active {
          font-weight: bold;
        }
      }

      @media (max-width: 600px) {
        display: none;
      }
    }

    .sub-nav {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 100;

      .nav {
        margin-right: 2rem;

        button {
          font-weight: bold;
          border: 0;
          background: transparent;
          font-size: 1.6rem;
          padding: 0 2rem;
          cursor: pointer;
          transition: all 0.3s;

          &:first-child {
            border-right: 1px solid;
          }
        }
      }

      @media (max-width: 600px) {
        .nav {
          display: block;

          button {
            display: none;
          }
        }
      }
    }

    .search-block {
      text-indent: -9999rem;
      width: 6rem;
      height: 2rem;
      display: inline-block;
      cursor: pointer;
      position: relative;
      top: 0.3rem;

      svg {
        width: 2rem;
        height: 2rem;
        display: block;

        .stroke {
          fill: none;
          stroke: #99a3bc;
          stroke-width: 2px;
          fill-rule: evenodd;
          transition: all 0.3s;
        }
      }

      &:hover {
        opacity: 0.8;
      }
    }

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 4.8rem;
        width: auto;
        max-width: 16rem;
      }
    }
  }
}
