/* Web Client Page - Activate Page */

.client-page {
  .activate-wrapper {
    margin: 2rem 0;
    padding: 0 2rem;

    h1 {
      max-width: 50rem;
      line-height: 1.3;
    }

    p {
      margin: 2.5rem 0 3rem;
    }

    .react-pin-code-input {
      display: flex;
      width: 100%;
    }

    .btn {
      margin: 0 auto;
      max-width: 20rem;
      width: 100%;
      text-align: center;
      display: inline-block;
    }
  }

  .success-message {
    .success-icon {
      display: flex;
      position: relative;
      margin: 0 auto 2rem;
      border-radius: 100%;
      width: 5rem;
      height: 5rem;
      background: #99a3ba;
      align-items: center;
      justify-content: center;
      opacity: 0;
      animation: success-icon 0.3s linear forwards;

      @keyframes success-icon {
        to {
          opacity: 1;
        }
      }

      svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      .stroke {
        fill: none;
        stroke: #ffffff;
        stroke-width: 2px;
        fill-rule: evenodd;
        animation: checkmark 0.3s linear 0.3s forwards;
        stroke-dasharray: 50;
        stroke-dashoffset: 50;

        @keyframes checkmark {
          to {
            stroke-dashoffset: 0;
          }
        }
      }
    }
  }
}
