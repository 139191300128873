/* Page wrapper */

.page-wrapper {
  margin-left: 30rem;
  padding: 4rem;
  position: relative;

  @media (max-width: 1200px) {
    margin-left: 0;
    padding: 2rem;
  }
}
