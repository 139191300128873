/* Main */

@import "./reset";
@import "./variables";
@import "./form";
@import "./select";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");

html {
  font-size: 8px;
}

body {
  background-color: $main-bg-color;
  color: $main-font-color;
  font-family: "Inter", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.btn {
  display: flex;
  align-items: center;
  border: 0;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  font-weight: 600;
  cursor: pointer;
  text-align: center;
  transition: background-color 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: all 0.3s;
  vertical-align: middle;

  &.active {
    opacity: 0.6;
    border: 0;
  }
}

.btn-primary {
  background-color: $main-accent-color;
  color: $secondary-accent-color;

  &:focus,
  &:hover {
    background-color: darken($main-accent-color, 5%);
    color: $secondary-accent-color;
    outline: none;
    border: 0;
  }
}

.btn-secondary {
  background-color: transparent;
  color: #99a3ba;

  &:focus,
  &:hover {
    background-color: transparent;
    color: #252c37;
  }
}

.link {
  color: $main-accent-color;
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    color: darken($main-accent-color, 5%);
  }
}

.invalid {
  color: $invalid-primary-color;
}

.disabled-container {
  position: relative;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.5;
    background-color: #f9fbff;
  }
}

h1 {
  font-size: 3.2rem;
  margin: 0.5em 0;
  font-weight: 500;
}

h2 {
  font-size: 2.4rem;
  margin: 0.5em 0;
  font-weight: 500;
}

h4 {
  font-size: 1.3rem;
  margin: 0.5em 0;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
}

@media (max-width: 1400px) {
  html {
    font-size: 8px;
  }
}

.custom-tooltip {
  background-color: #ffffff;
  margin: 10px;
  padding: 10px;
}
