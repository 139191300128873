/* Web Client Page - Footer */

.client-page {
  .footer {
    background: #ecf1fa;
    padding: 3rem 5% 5rem;
    z-index: 99;
    position: relative;

    a {
      text-decoration: none;
      color: #252c37;
    }

    small {
      color: #99a3ba;
      font-size: 1.4rem;
    }

    @media (max-width: 480px) {
      text-align: center;
    }

    .footer-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 700px) {
        display: block;
      }

      @media (max-width: 480px) {
        text-align: center;
      }
    }

    .apps-logos {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 4rem;

      a {
        max-width: 15rem;
        height: 3.5rem;
        display: block;
        margin-right: 4rem;
        transition: all 0.3s;

        svg {
          width: auto;
          height: 100%;
          max-width: 100%;

          .fill {
            fill: #252c37;
            transition: all 0.3s;
          }
        }

        &.roku-logo {
          height: 2.7rem;
        }

        &.amazon-fire-tv-logo {
          height: 4.3rem;
        }
      }

      @media (max-width: 480px) {
        justify-content: space-between;
        flex-wrap: wrap;

        a {
          width: 45%;
          margin: 1rem 2%;
          display: block;
          max-width: none;
          text-align: center;
          border: 1px solid;
          padding: 1rem;
          border-radius: 0.3rem;
          height: 5rem;

          &.android-tv-logo {
            height: 5rem;
            padding: 1.5rem 1rem;
          }

          &.roku-logo {
            height: 5rem;
            padding: 1.3rem 1rem;
          }

          &.amazon-fire-tv-logo {
            height: 5rem;
          }
        }
      }
    }

    .social-logos {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      a {
        max-width: 2.5rem;
        height: 2.5rem;
        display: block;
        margin-right: 3rem;
        transition: all 0.3s;

        svg {
          width: auto;
          height: 100%;
          max-width: 100%;

          .fill {
            fill: #99a3ba;
            transition: all 0.3s;
          }
        }
      }

      @media (max-width: 700px) {
        margin-top: 3rem;
      }

      @media (max-width: 480px) {
        justify-content: center;
      }
    }
  }
}
