/* Resource field */

@import "../../assets/styles/variables.scss";

.resource-field {
  width: 100%;
}

.resource-table-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 1rem;
  box-shadow: 0 0 2rem rgba(153, 163, 186, 0.2);
  border: 2px solid #ffffff;

  span {
    color: #99a3ba;
    vertical-align: top;
  }

  p {
    margin: 0;
    padding: 0;
    color: #252c37;
  }

  .inline-wrapper {
    margin-bottom: 1rem;

    span,
    p {
      display: inline;
      margin-right: 0.5rem;
    }
  }

  .block-wrapper {
    width: 100%;
    margin-bottom: 1rem;

    p {
      margin-top: 0.5rem;
    }

    span,
    p {
      display: block;
      width: 100%;
    }
  }
}

.resource-items-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  max-height: 46vh;
  overflow-y: scroll;
  padding: 0;
  width: 100%;
  position: relative;
  padding-top: 2rem;
  margin-bottom: 2rem;

  &.gallery {
    max-height: calc(100vh - 26rem);
    justify-content: flex-start;

    li {
      &:nth-child(3n + 3) {
        margin-right: 0;
      }

      &.disabled {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0.7;
          background: #f9fbff;
        }
      }

      &.load-more-item {
        padding: 0;
        flex-basis: 100%;
        margin: 2rem 0 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        width: 100%;
        cursor: auto;

        .load-btn {
          margin: 0 auto;
          display: block;
          max-width: 100%;
          width: 16rem;
          background: #f0f3f9;
          padding: 1rem;
          border-radius: 0.6rem;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 5rem;
          color: #6f7d98;
          font-weight: 600;

          @media (max-width: 1400px) {
            height: 6rem;
            font-size: 1.6rem;
            width: 18rem;
          }

          &:hover {
            cursor: pointer;
            background: #f4f7fb;
          }
        }
      }
    }

    .asset-name {
      margin: 0;
    }
  }

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
    max-width: 50%;
    order: 2;
    padding: 0 1.5rem;
    position: relative;
    width: 50%;

    @media (max-width: 480px) {
      max-width: 100%;
      width: 100%;
    }

    input[type="checkbox"] {
      opacity: 0;
      position: absolute;
      right: 2.5rem;
      top: 1rem;
      transition: all 0.3s;
    }

    .img-block {
      align-items: center;
      border-radius: 0.5rem;
      display: flex;
      height: 15rem;
      justify-content: center;
      margin: 0 auto;
      padding: 2rem 1rem;
      border: 2px solid transparent;

      img {
        max-height: 15rem;
      }
    }

    &:not(.disabled).selected,
    &:not(.disabled):hover {
      input[type="checkbox"] {
        opacity: 1;
        transition: all 0.3s;
        cursor: pointer;
      }

      .asset-block {
        height: 100%;

        .img-block {
          border: 2px solid rgba($main-accent-color, 0.6);
          background: #ffffff;
          transition: all 0.3s;

          img {
            max-height: 10rem;
            transition: all 0.3s;
          }
        }

        .asset-name {
          color: #252c37;
        }
      }

      .resource-table-wrapper {
        border: 2px solid rgba($main-accent-color, 0.6);
        background: #ffffff;
        transition: all 0.3s;
      }
    }

    &:not(.disabled):hover {
      .asset-block {
        .img-block {
          border: 2px solid #e2e9f6;
        }
      }

      .resource-table-wrapper {
        border: 2px solid #e2e9f6;
        cursor: pointer;
      }
    }
  }

  .asset-block {
    border-radius: 3px;
    display: inline-block;
    padding-bottom: 2rem;
    width: 100%;
    height: 100%;

    .img-thumb {
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 15rem;
      max-width: 100%;
      transition: all 0.3s;
    }

    img,
    video {
      border-radius: 3px;
      display: block;
      margin: 0 auto;
      max-height: 15rem;
      max-width: 80%;
      transition: all 0.3s;
    }
  }

  .asset-btn,
  .asset-name {
    color: #6f7d98;
    display: inline-block;
    font-weight: 500;
    overflow: hidden;
    padding: 2rem 1rem;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .asset-btn {
    cursor: pointer;
  }
}

.resource-items-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  max-height: 46vh;
  overflow-y: scroll;
  padding: 2rem 0 0 0;
  width: 100%;
  position: relative;
  margin-top: 0;
  margin-bottom: 1rem;

  li {
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0;
    max-width: 50%;
    order: 2;
    padding: 0 1.5rem;
    position: relative;
    width: 50%;

    @media (max-width: 480px) {
      max-width: 100%;
      width: 100%;
    }
  }

  .assets-image-field-item {
    .img-block {
      border-radius: 3px;
      display: inline-block;
      padding-bottom: 1rem;
      width: 100%;

      img {
        border-radius: 3px;
        display: block;
        margin: 0 auto;
        max-height: 15rem;
        max-width: 100%;
        transition: all 0.3s;
      }
    }
  }

  .actions-row {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 2rem;
    right: 0;

    .edit-action {
      display: block;
      height: 2rem;
      margin: 0 2rem;
      padding: 0;
      position: relative;
      text-indent: -9999rem;
      width: 2rem;

      &:after,
      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        width: 2rem;
      }

      &:before {
        background-image: url("../../assets/images/edit-icon.svg");
      }

      &:after {
        background-image: url("../../assets/images/edit-icon-active.svg");
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }

    .delete-action {
      display: block;
      height: 2rem;
      margin: 0 2rem;
      padding: 0;
      position: relative;
      text-indent: -9999rem;
      width: 2rem;

      &:after,
      &:before {
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        content: "";
        display: block;
        height: 2rem;
        left: -1rem;
        position: absolute;
        top: 0;
        transition: opacity 0.3s;
        width: 2rem;
      }

      &:before {
        background-image: url("../../assets/images/delete-icon.svg");
      }

      &:after {
        background-image: url("../../assets/images/delete-icon-active.svg");
        opacity: 0;
      }

      &:hover {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.actions-wrapper {
  width: 100%;
}
