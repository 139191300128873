/* Error message */

.error-message {
  background-color: #ffdbcc;
  color: #252c37;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;

  .error-icon {
    margin-right: 1rem;
  }
}
