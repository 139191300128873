/* Web Client Page - Login */

.client-page {
  .login-modal {
    position: fixed;
    z-index: 360;

    h3 {
      font-size: 2.8rem;
      line-height: 1.5;
      letter-spacing: 0.05rem;
      color: #0d2039;
      margin: 0 0 3rem;
    }

    .login-wrapper {
      align-items: center;
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;

      .close-btn {
        border-radius: 100%;
        border: none;
        cursor: pointer;
        display: block;
        height: 3.5rem;
        margin: 0;
        opacity: 0.7;
        padding: 0;
        position: absolute;
        right: -3.5rem;
        text-indent: -9999rem;
        top: -3.8rem;
        width: 3.5rem;
        z-index: 361;

        &:after,
        &:before {
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
          content: "";
          display: block;
          height: 1.5rem;
          left: 1rem;
          position: absolute;
          top: 1rem;
          transition: opacity 0.3s;
          width: 1.5rem;
        }

        &:before {
          background-image: url("../../../assets/images/close-icon.svg");
        }

        &:after {
          background-image: url("../../../assets/images/close-icon-active.svg");
          opacity: 0;
        }

        &:hover {
          &:before {
            opacity: 0;
          }

          &:after {
            opacity: 1;
          }
        }

        @media (max-width: 480px) {
          top: 1.5rem;
          right: 1.5rem;
          height: 2rem;
          width: 2rem;
          background: transparent !important;

          &:after,
          &:before {
            height: 2rem;
            width: 2rem;
            top: 0;
            left: 0;
          }
        }
      }

      &:before {
        background-color: #d3ddeb;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        height: 100vh;
        left: 0;
        opacity: 1;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 350;
      }

      .login-block {
        max-width: 90%;
        position: relative;
        width: 45rem;
        z-index: 360;
        background: #ffffff;
        padding: 5rem 4rem;
        border-radius: 0.6rem;
        text-align: center;
      }

      .form {
        width: 100%;
        margin-bottom: 2rem;

        input[type="text"],
        input[type="password"] {
          color: #000000;
        }

        input {
          width: 100%;
          margin-bottom: 1rem;
          border-radius: 0.6rem;

          &.btn {
            text-align: center;
            justify-content: center;
            border: 0;
            outline: none;
            box-shadow: none;
            margin-top: 1.5rem;
            border-radius: 0.6rem;
            height: 5.4rem;
          }
        }

        a.btn {
          text-align: center;
          justify-content: center;
          border: 0;
          outline: none;
          box-shadow: none;
          margin-top: 1.5rem;
          border-radius: 0.6rem;
          height: 5.4rem;
          max-width: 20rem;
          margin: 0 auto;
        }
      }

      .link {
        margin-top: 0.5rem;
        display: inline-block;
      }

      h3.smaller {
        font-size: 2.4rem;
        margin: 0;
      }

      .login-footer {
        border-top: 1px solid #ecf1fa;
        padding: 4rem;
        width: calc(100% + 8rem);
        position: relative;
        left: -4rem;
        bottom: -4rem;
        color: #0d2039;
      }
    }
  }
}
