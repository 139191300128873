/* Table title */

.table-title {
  padding: 1.5rem 3rem;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;

  h2 {
    color: #252c37;
    display: inline-block;
    margin: 1rem 0;
    max-width: calc(100% - 14rem);
  }

  .total {
    display: inline-block;
    color: #99a3ba;
    border-left: 1px solid #e2e9f6;
    margin-left: 2rem;
    padding-left: 2rem;
    font-weight: 500;
  }

  @media (max-width: 800px) {
    padding: 1.5rem 2rem;

    .total {
      font-size: 1.5rem;
      margin-left: 1rem;
      padding-left: 1rem;
    }
  }

  @media (max-width: 480px) {
    h2 {
      font-size: 2rem;
    }
  }
}
