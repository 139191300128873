/* Switcher */

@import "../../assets/styles/variables.scss";

.switcher {
  margin-bottom: auto;
  position: relative;

  @media (max-width: 1400px) {
    top: 0;
  }

  input[type="checkbox"] {
    height: 0;
    visibility: hidden;
    width: 0;
  }

  label {
    background: #ffffff;
    border-radius: 2rem;
    cursor: pointer;
    display: block;
    height: 18px;
    position: relative;
    text-indent: -9999px;
    width: 34px;
    border: 1px solid #dce5f3;
  }

  label:after {
    background: #dce5f3;
    border-radius: 1.6rem;
    content: "";
    height: 14px;
    left: 1px;
    position: absolute;
    top: 1px;
    transition: 0.3s;
    width: 14px;
  }

  input:checked + label {
    background: $main-accent-color;
    border: 1px solid $main-accent-color;
  }

  input:checked + label:after {
    left: calc(100% - 1px);
    transform: translateX(-100%);
    background: #ffffff;
  }

  label:active:after {
    width: 14px;
  }
}
