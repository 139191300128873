/* Loader */

.center-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
  text-indent: -9999rem;
  margin: 2rem 0;
}

.loader:after,
.loader:before {
  content: "";
  position: absolute;
  border: 0.3rem solid #99a3ba;
  opacity: 1;
  border-radius: 50%;
  animation: loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader:after {
  animation-delay: -0.5s;
}

@keyframes loader {
  0% {
    top: 3.6rem;
    left: 3.6rem;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 7.2rem;
    height: 7.2rem;
    opacity: 0;
  }
}
