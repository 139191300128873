/* Web Client Page - Description */

.client-page {
  .description {
    flex: 1;
    padding: 3rem;
    width: 59%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 0.6rem 0.6rem 0;
    transition: all 0.3s;
    position: relative;
    z-index: 110;

    .title {
      font-size: 2vw;
      font-weight: 600;
      margin: 0;
      max-width: 100%;
    }

    .subtitle {
      font-size: 1.6rem;
      font-weight: 600;
      display: block;
      margin: 1rem 0;
      color: #99a3ba;
    }

    p {
      margin-top: 1rem;
      max-width: 100%;
      font-size: 1.8rem;
      line-height: 1.5;
    }

    .next {
      margin: 1.5rem 0 0;
      transition: opacity 0.5s ease-in;

      h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        font-size: 1.2rem;
        letter-spacing: 0.12rem;
        font-weight: 900;
      }

      .next-title {
        font-size: 2rem;
      }
    }
  }
}
