/* Form group */
@import "assets/styles/variables";

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 3rem;

  input {
    width: 100%;
  }

  label {
    color: #99a3ba;
    font-weight: 500;
    margin: 0 0 1rem;
  }

  &.invalid {
    label {
      color: $invalid-primary-color;
    }

    input {
      border-color: $invalid-secondary-color;
      color: $invalid-primary-color;

      &:focus {
        border-color: $invalid-secondary-color;
        box-shadow: 0 0 0 1px $invalid-secondary-color;
      }
    }

    .select {
      .custom__single-value {
        color: $invalid-primary-color;
      }

      .custom__control {
        border-color: $invalid-secondary-color;

        &:hover {
          border-color: $invalid-secondary-color;
        }
      }

      .custom__control--is-focused {
        border-color: $invalid-secondary-color;
        box-shadow: 0 0 0 1px $invalid-secondary-color;

        &:hover {
          border-color: $invalid-secondary-color;
        }
      }
    }
  }
}

.form-control {
  width: 100%;
}
