/* Select */

@import "../../assets/styles/variables.scss";

.select {
  width: 100%;
  color: #252c37;

  input {
    height: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .css-1hwfws3 {
    height: 3.6rem;
  }

  .custom__control {
    border-color: #dce5f3;
    border-radius: 0.5rem;

    &:hover {
      border-color: #dce5f3;
    }
  }

  .custom__control--is-focused {
    border-color: rgba($main-accent-color, 0.6);
    box-shadow: 0 0 0 1px rgba($main-accent-color, 0.6);

    &:hover {
      border-color: rgba($main-accent-color, 0.6);
    }
  }

  .custom__indicator-separator {
    margin: 0;
    background-color: #dce5f3;
  }

  .custom__indicator {
    padding: 1rem 1.5rem;
  }

  .custom__option {
    font-size: 1.5rem;

    &:active {
      background-color: #f9fbff;
    }
  }

  .custom__option--is-focused {
    background-color: #f9fbff;
    font-size: 1.5rem;

    &:active {
      background-color: #f9fbff;
    }
  }

  .custom__option--is-selected,
  .custom__option--is-focused.custom__option--is-selected {
    background-color: #b1b9cc;
    font-size: 1.5rem;

    &:active {
      background-color: #f9fbff;
    }
  }

  .custom__value-container {
    min-height: 5rem;
    padding: 0 1.5rem;
    font-size: 1.5rem;
  }

  .custom__menu-list {
    max-height: 11rem;
  }
}
