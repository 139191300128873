/* Main nav */

@import "../../assets/styles/variables.scss";

.main-nav {
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  font-weight: 200;
  justify-content: flex-start;
  left: 0;
  padding-top: 10rem;
  position: fixed;
  top: 0;
  width: 30rem;
  overflow: hidden;
  z-index: 200;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, $main-bg-color, $secondary-bg-color);
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: 1200px) {
      width: 0;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    height: 10rem;
    justify-content: center;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    img {
      max-height: 4.7rem;
      max-width: 17rem;
    }
  }

  .main-nav-icon {
    padding: 3.5rem 2.2rem;
    position: absolute;
    top: 0;
    height: 10rem;
    left: 0;
    width: 7rem;
    display: none;
    cursor: pointer;

    &:before,
    &:after,
    div {
      background: $secondary-font-color;
      content: "";
      display: block;
      height: 2px;
      border-radius: 0.2rem;
      margin: 3px 0;
      transition: all 0.3s;
      width: 2.6rem;
    }
  }

  .nav {
    list-style: none;
    margin: 0 2rem;
    padding: 0;

    @media (max-width: 1200px) {
      display: none;
    }
  }

  .nav-item {
    &:nth-child(4) .nav-item-container .label {
      margin-top: -0.75rem;
    }

    .nav-item-container {
      border-radius: 0.5rem;
      color: $secondary-font-color;
      cursor: pointer;
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      padding: 1rem 1.5rem;
      margin-bottom: 0.2rem;
      position: relative;
      text-decoration: none;
      transition: all 0.3s;
      height: 5rem;

      .label {
        display: inline-block;
        position: absolute;
        left: 5.5rem;
        top: 50%;
        margin-top: -0.9rem;
        transition: all 0.3s;
      }

      .nav-icon {
        margin-right: 1.5rem;
        width: 2.7rem;
        display: inline-block;

        svg {
          display: block;
          margin: 0 auto;
          width: 100%;

          &.tags-icon {
            width: 85%;
          }

          &.categories-icon {
            width: 85%;
            margin-top: 0.2rem;
          }

          &.sources-icon {
            width: 75%;
            margin-top: -0.2rem;
          }

          &.live-sources-icon {
            width: 90%;
            margin-top: 0.2rem;
          }

          &.agents-icon {
            width: 90%;
            margin-top: -0.1rem;
          }

          &.assets-icon {
            width: 90%;
          }

          &.settings-icon {
            width: 75%;
            margin-top: 0.1rem;
          }
        }

        .stroke {
          fill: none;
          stroke: $secondary-font-color;
          stroke-width: 2px;
          fill-rule: evenodd;
          transition: all 0.3s;
        }

        .fill {
          fill: $secondary-font-color;
          transition: all 0.3s;
        }

        .opacity {
          opacity: 0.5;
        }
      }

      &:hover {
        color: $main-font-color;

        .nav-icon {
          .stroke {
            stroke: $main-accent-color;
          }

          .fill {
            fill: $main-accent-color;
          }
        }
      }
    }

    &.active .nav-item-container {
      background: $accent-bg-color;
      color: $main-font-color;

      .nav-icon {
        .stroke {
          stroke: $main-accent-color;
        }

        .fill {
          fill: $main-accent-color;
        }
      }
    }

    &.nav-item-dropdown {
      position: relative;

      .nav-item-container:before {
        content: "";
        background-position: center;
        background-repeat: no-repeat;
        background-size: auto 100%;
        display: block;
        height: 1.2rem;
        left: 20rem;
        position: absolute;
        top: 1.7rem;
        transform: rotate(180deg);
        transition: all 0.3s;
        width: 1.2rem;
        background-image: url("../../assets/images/back-icon.svg");
        z-index: 1;
      }

      &.active {
        .nav-item-container:before {
          transform: rotate(270deg);
        }
      }

      .nav .nav-item-container:before {
        display: none;
      }
    }

    .nav {
      margin: 0;
    }
  }
}

@media (max-width: 1200px) {
  .main-nav {
    transition: all 0.3s;
    width: 7rem;
    z-index: 400;

    .logo {
      display: none;
    }

    .nav {
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .main-nav-icon {
      display: block;
    }

    .nav-item {
      padding: 0;

      .nav-item-container {
        height: 5rem;
        padding: 1rem;
        width: 5rem;

        .nav-icon {
          margin: 0;
          width: 3rem;
        }
      }

      .label {
        opacity: 0;
        min-width: 30rem;
      }
    }

    &.active {
      width: 30rem;

      .nav {
        margin: 0 2rem;

        .nav {
          margin: 0;
        }
      }

      .logo {
        display: block;
        z-index: 200;
        left: 7rem;
        top: 2.5rem;
        width: auto;
      }

      .nav-item {
        .nav-item-container {
          width: 100%;
        }

        .label {
          opacity: 1;
        }
      }

      .main-nav-icon {
        &:before {
          transform: translateY(0.65rem) rotate(135deg);
        }

        &:after {
          transform: translateY(-0.65rem) rotate(-135deg);
        }

        div {
          transform: scale(0);
        }
      }

      .nav-item .nav {
        position: relative;
        display: block !important;
      }
    }

    &:not(.active) {
      .nav-item.nav-item-dropdown .nav-item-container {
        &:before {
          display: none;
        }
      }

      .nav-item.nav-item-dropdown .nav {
        display: none;
      }
    }

    .nav-item.nav-item-dropdown .nav-item-container:before {
      left: 80%;
    }
  }
}

@media (max-width: 1200px) {
  .main-nav {
    z-index: 1;
    position: absolute;

    .main-nav-icon {
      z-index: 200;
    }

    &.active {
      z-index: 200;
      position: fixed;

      &:before {
        bottom: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        font-weight: 200;
        justify-content: flex-start;
        left: 0;
        padding-top: 10rem;
        position: fixed;
        top: 0;
        width: 32rem;
        z-index: 200;
      }

      .nav {
        width: 28rem;
        position: fixed;
        z-index: 200;
        display: block;
      }
    }
  }
}

@media (max-width: 480px) {
  .main-nav {
    z-index: 1;

    &.active {
      z-index: 200;
      width: 100%;

      &:before {
        width: 100%;
      }

      .nav {
        display: block;
        width: calc(100% - 4rem);

        .nav {
          width: 100%;
        }
      }
    }

    .nav-item.nav-item-dropdown .nav-item-container:before {
      left: auto;
      right: 2rem;
    }
  }
}
