/* Editor title */

.modal-title {
  color: #99a3ba;
  margin: 0 0 5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1.6rem;
}

.back-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 1.6rem;
  margin: 0 1rem 0 0;
  padding: 0;
  width: 1.6rem;
  position: relative;

  &:after,
  &:before {
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    content: "";
    display: block;
    height: 1.6rem;
    left: 0;
    position: absolute;
    top: -0.2rem;
    transition: opacity 0.3s;
    width: 1.6rem;
  }

  &:before {
    background-image: url("../../assets/images/back-icon.svg");
  }

  &:after {
    background-image: url("../../assets/images/back-icon-active.svg");
    opacity: 0;
  }

  &:hover {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}
