/* Table header */

.table-header {
  align-items: center;
  border-bottom: 1px solid #e2e9f6;
  display: flex;
  height: 7.5rem;
  justify-content: space-between;
  position: relative;
}
